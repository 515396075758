@use '@angular/material' as mat;
@use '@angular/flex-layout/core/sass/layout-bp';
@use '../variables';

// Material Button

@mixin mm-component-material-button($theme: variables.$theme-selected) {
  $color: mat.get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mat-mdc-button-base {
    &[mat-stroked-button],
    &[mat-flat-button],
    &[mat-raised-button] {
      --mdc-outlined-button-outline-color: #{mat.get-color-from-palette(variables.$mm-grey, 150)};

      height: unset;
      @include layout-bp.layout-bp(lt-lg) {
        width: 100%;
      }
      @include mat.typography-level(variables.$mm-typography, button);
      border-radius: 10px;
      text-transform: uppercase;
      border-width: 2px;
      white-space: normal;
      padding: 24px;

      &.mat-primary {
        --mdc-outlined-button-outline-color: #{mat.get-color-from-palette($primary-color, 500)};
        color: #{--mdc-outlined-button-outline-color};

        &.light {
          box-shadow: 0px 0px 4px 0px mat.get-color-from-palette($primary-color, 400);
          border: none;
        }
      }

      &.mat-accent {
        --mdc-outlined-button-outline-color: #{mat.get-color-from-palette($primary-color, 500)};
      }

      &.mm-small-button,
      &:disabled {
        --mdc-outlined-button-outline-color: #{mat.get-color-from-palette(variables.$mm-grey, 100)};
        color: mat.get-color-from-palette(variables.$mm-grey, 175);
      }

      & .mat-progress-spinner {
        display: inline-block;
      }

      &.mm-compact {
        padding: 14px 30px;
      }

      &.mm-borderless {
        border-color: transparent;

        &:not([class*='mat-elevation-z']) {
          box-shadow: none;
        }
      }

      &.mm-small-compact {
        border-width: 1px;
        border-radius: 5px;
        padding: 6px 10px;
        @include mat.typography-level(variables.$mm-typography, small-4);

        &.mat-primary {
          color: mat.get-color-from-palette($primary-color, 450);
          --mdc-outlined-button-outline-color: #{mat.get-color-from-palette($primary-color, 450)};
        }

        &:disabled {
          color: mat.get-color-from-palette(variables.$mm-grey, 175);
          --mdc-outlined-button-outline-color: #{mat.get-color-from-palette(variables.$mm-grey, 100)};
        }
      }

      &.mm-small-button {
        border: none;
        border-radius: 5px;
        padding: 14px 20px;
        box-shadow: none;

        &.mat-primary {
          background-color: mat.get-color-from-palette($primary-color, 50);
        }

        &.mat-accent {
          background-color: mat.get-color-from-palette($accent-color, 100);
        }
      }
    }

    &.mat-mdc-unelevated-button {
      &.mm-compact {
        padding: 17px 30px;
      }
    }

    &.mm-action {
      border: 1px solid mat.get-color-from-palette($accent-color, 100);
      border-radius: 5px;
      width: 30px;
      height: 30px;
      line-height: 30px;

      & .mat-icon {
        line-height: 22px;
      }
    }

    &[mat-raised-button] {
      color: mat.get-color-from-palette($primary-color, A100);
    }
  }
}

@mixin mm-component-material-button-color($theme: variables.$theme-selected) {
  $color: mat.get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mat-mdc-button-base {
    &[mat-stroked-button],
    &[mat-flat-button],
    &[mat-raised-button] {
      &.mat-primary {
        --mdc-outlined-button-outline-color: #{mat.get-color-from-palette($primary-color, 500)};

        &.light {
          box-shadow: 0px 0px 4px 0px mat.get-color-from-palette($primary-color, 400);
        }
      }

      &.mat-accent {
        --mdc-outlined-button-outline-color: #{mat.get-color-from-palette($primary-color, 500)};
      }

      &:disabled {
        --mdc-outlined-button-outline-color: #{mat.get-color-from-palette($warn-color, 100)};
        border-color: mat.get-color-from-palette($warn-color, 100);
      }
    }

    &[mat-raised-button] {
      color: mat.get-color-from-palette($primary-color, A100);
    }
  }
}
